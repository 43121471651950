/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
library.add(fab)

interface Props {
	// location: Location
	// title: string
	children?: JSX.Element[]
}

const Layout = ({ children }: Props) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			{/* <Header siteTitle={data.site.siteMetadata.title} /> */}
			<div
				style={{
					margin: `0 auto`,
					// maxWidth: 960,
					padding: `0 0 1.45rem`,
				}}
			>
				<main style={{ height: `100vh` }}>
					<div className="container">{children}</div>
				</main>
				<footer className="site-footer">
					<div className="md-col-8 col-12 mx-auto">
						© {new Date().getFullYear()}, Built with
						{` `}
						<a href="https://www.gatsbyjs.org">Gatsby</a>
						{/* <span>&copy; {{ site.time | date: '%Y' }}
      {% if site.author %} 
        <a href="mailto:{{ site.email }}" >{{site.author}}</a>
      {% else %} 
        <a href="mailto:{{ site.email }}" >{{site.title}}</a>
      {% endif %}
      
    </span> */}
					</div>
				</footer>
			</div>
		</>
	)
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
